export   const phoneNumberToDisplay = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7)
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };
  
  export const millisecondsToMinutesDisplay = (milliseconds: number): number => {
    if (milliseconds <= 0) {
      return 0;
    }
    return Math.floor(milliseconds / (1000 * 60));
  }

