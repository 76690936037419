import * as React from "react"
import {
  ChakraProvider,
  // Box,
  // Text,
  // VStack,
  // Stack,
  // Button,
  // Container,
  // Heading,
} from "@chakra-ui/react"
import { theme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'
import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'
import '@fontsource/plus-jakarta-sans'
// import { createPopup } from '@typeform/embed';


// import logo from "./logo-take-2.png"
import PhoneNumberForm from "./DemoForm"
// import { PopupButton } from "@typeform/embed-react"
// import VideoSection from "./VideoSelection"
const proTheme = extendTheme(theme)
const extenstion = {
  colors: { ...proTheme.colors, brand: proTheme.colors.teal },
  styles: {
    global: {
      // Set the background color for the whole page
      body: {
        // bg: '#dddad0',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        color: "rgb(8 10 71)"
      },
    },
  },
  fonts: {
    // Set the default font family for heading and body text
    heading: "'Plus Jakarta Sans', sans-serif",
    body: "'Plus Jakarta Sans', sans-serif",
  },

}

const myTheme = extendTheme(extenstion, proTheme)


// const handleClick = () => {
//   const popup = createPopup('Dt7QSRbx', {
//   });

//   popup.open();
// };

export const App = () => (
  <ChakraProvider theme={myTheme} >
   
          {/* This is where your demo section will go, replacing the image */}
          <PhoneNumberForm />
    


  </ChakraProvider>
);