import { WSCommand, WSErrorMessage, WSEventLogMessage, WSLogMessage } from "./shared";
import * as Twilio from "@twilio/voice-sdk";
import { EventEmitter } from "eventemitter3";
import { LiveClient } from "./LiveClient/LiveClient";

export interface StartCallConfig {
	callId?: string;
	sampleRate: number;
	stream?: MediaStream;
	enableUpdate?: boolean;
}

export class WebSdk extends EventEmitter {
	private liveClient: LiveClient | null = null;
	private isCalling = false;
	static twilioSdk = Twilio;
	public device: Twilio.Device | null;
	constructor(config: {
		deviceToken: string;
		deviceConfig?: Twilio.Device.Options;
	}) {
			super();

		this.device = new Twilio.Device(config.deviceToken, config.deviceConfig);
		this.device.audio?.incoming(false);
		this.device.audio?.disconnect(false);
		this.device.audio?.outgoing(false);
	}

	public async connect({ url }: { url: string }) {
		this.liveClient = new LiveClient({ url });
		this.setupEvents();
	}

	public async disconnect() {
		if (this.liveClient) {
			this.liveClient.close();
			this.liveClient = null;
		}
	}

	public async startCall(startCallConfig: StartCallConfig): Promise<void> {
		try {
			console.log("startCall");
			this.isCalling = true;
		} catch (err) {
			this.emit("error", (err as Error).message);
		}
	}

	public stopCall(): void {
		this.isCalling = false;
		this.liveClient?.close();

		// Release references to allow for garbage collection
		this.liveClient = null;
	}

	private setupEvents(): void {
        if (!this.liveClient) {
            return;
        }
		this.liveClient.on("close", (code: number, reason: string) => {
			if (this.isCalling) {
				this.stopCall();
			}
			this.emit("end", { code, reason });
		});

		this.liveClient.on("clear", () => {});

		this.liveClient.on("call-ready", () => {
			this.emit("call-ready");
		});

		this.liveClient.on("close", () => {
			this.emit("close");
		});

		this.liveClient.on("disconnect", () => {
			this.emit("disconnect");
		});

		this.liveClient.on("error", (error: WSErrorMessage) => {
			this.emit("error", error);
			if (this.isCalling) {
				this.stopCall();
			}
		});

		this.liveClient.on("log", (log: WSLogMessage | WSEventLogMessage) => {
			this.emit("log", log);
		});

		this.liveClient.on("media", (media: Uint8Array) => {
			this.emit("media", media);
		});

		this.liveClient.on("open", () => {
			this.emit("start");
		});

		this.liveClient.on("reconnect", () => {
			this.emit("reconnect");
		});

		this.liveClient.on("update", (update: unknown) => {
			this.emit("update", update);
		});
	}

	public sendCommand(command: WSCommand, callId: string) {
		if (!this.liveClient) {
			console.log("LiveClient not initialized.");
			return;
		}
		this.liveClient.sendCommand(command, { callId });
	}

	public sendStop(callId: string) {
		if (!this.liveClient) {
			console.log("LiveClient not initialized.");
			return;
		}
		this.liveClient.sendStop(callId);
	}

	public streamLogs() {}
}
