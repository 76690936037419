import { WSCommand, WSCommandMessage, WSMediaMessage, WSStartMessage, WSStopMessage } from "../shared";
import { EventEmitter } from "eventemitter3";

export interface AudioPlayerConfig {
	callId?: string;
	enableUpdate?: boolean;
	url: string;
}

export class LiveClient extends EventEmitter {
	private ws: WebSocket;
	private pingTimeout: ReturnType<typeof setTimeout> | null = null;
	private pingInterval: ReturnType<typeof setInterval> | null = null;
	private wasDisconnected = false;
	private pingIntervalTime = 5000;

	constructor(config: AudioPlayerConfig) {
		super();

		this.ws = new WebSocket(config.url);
		this.ws.binaryType = "arraybuffer";

		this.ws.onopen = () => {
			this.emit("open");
			// this.startPingPong();
		};

		this.ws.onmessage = (event) => {
			if (typeof event.data !== "string") {
				return;
			}

			const eventData = JSON.parse(event.data);
			switch (eventData.event) {
				case "clear":
					this.emit("clear");
					// audioPlayer.stop();
					break;
				case "call-ready":
					this.emit("call-ready");
					break;
				case "log":
					this.emit("log", eventData);
					break;
				case "media": {
					const binaryData = atob(eventData.media.payload);

					// Create an ArrayBuffer to hold the binary data.
					const arrayBuffer = new ArrayBuffer(binaryData.length);
					const uint8Array = new Uint8Array(arrayBuffer);
					for (let i = 0; i < binaryData.length; i++) {
						uint8Array[i] = binaryData.charCodeAt(i);
					}

					this.emit("media", uint8Array);
					break;
				}
				case "pong":
					console.log("pong");
					if (this.wasDisconnected) {
						this.emit("reconnect");
						this.wasDisconnected = false;
					}
					this.adjustPingFrequency(5000); // Reset ping frequency to 5 seconds
					break;

				case "start":
					console.log("start");
					break;
				default:
					break;
			}
		};
		this.ws.onclose = (event) => {
			this.stopPingPong();
			this.emit("close", event.code, event.reason);
		};
		this.ws.onerror = (event) => {
			this.stopPingPong();
			this.emit("error", event);
		};
	}

	startPingPong() {
		this.pingInterval = setInterval(() => this.sendPing(), this.pingIntervalTime);
		this.resetPingTimeout();
	}

	sendPing() {
		if (this.ws.readyState === WebSocket.OPEN) {
			const message = {
				event: "ping",
			};
			this.ws.send(JSON.stringify(message));
		}
	}

	adjustPingFrequency(newInterval: number) {
		if (this.pingIntervalTime !== newInterval) {
			if (this.pingInterval != null) {
				clearInterval(this.pingInterval);
			}
			this.pingIntervalTime = newInterval;
			this.startPingPong();
		}
	}

	resetPingTimeout() {
		if (this.pingTimeout != null) {
			clearTimeout(this.pingTimeout);
		}
		this.pingTimeout = setTimeout(() => {
			if (this.pingIntervalTime === 5000) {
				this.adjustPingFrequency(1000);
				this.pingTimeout = setTimeout(() => {
					this.emit("disconnect");
					this.wasDisconnected = true;
				}, 3000);
			}
		}, this.pingIntervalTime);
	}

	stopPingPong() {
		if (this.pingInterval != null) {
			clearInterval(this.pingInterval);
		}
		if (this.pingTimeout != null) {
			clearTimeout(this.pingTimeout);
		}
	}

	sendCommand(command: WSCommand, { callId }: { callId: string }) {
		if (this.ws.readyState !== WebSocket.OPEN) {
			console.log("Send command ws not open. WS state", this.ws.readyState);
			return;
		}
		const message: WSCommandMessage = {
			event: "command",
			callId: callId,
			command: {
				type: command,
			},
		};
		this.ws.send(JSON.stringify(message));
	}

	sendStart() {
		if (this.ws.readyState !== WebSocket.OPEN) {
			return;
		}
		const message: WSStartMessage = {
			event: "start",
		};
		this.ws.send(JSON.stringify(message));
	}

	sendStop(callId: string) {
		const message: WSStopMessage = {
			callId,
			event: "stop",
		};
		this.ws.send(JSON.stringify(message));
	}

	sendMedia(audio: Uint8Array) {
		if (this.ws.readyState !== WebSocket.OPEN) {
			console.log("ws not open");
			return;
		}

		const message: WSMediaMessage = {
			event: "media",
			media: {
				payload: audio.toString(),
			},
		};
		this.ws.send(JSON.stringify(message));
	}

	close() {
		this.ws.close();
	}
}

export function convertUint8ToFloat32(array: Uint8Array): Float32Array {
	const targetArray = new Float32Array(array.byteLength / 2);

	// A DataView is used to read our 16-bit little-endian samples out of the Uint8Array buffer
	const sourceDataView = new DataView(array.buffer);

	// Loop through, get values, and divide by 32,768
	for (let i = 0; i < targetArray.length; i++) {
		targetArray[i] = sourceDataView.getInt16(i * 2, true) / 2 ** (16 - 1);
	}
	return targetArray;
}

export function convertFloat32ToUint8(array: Float32Array): Uint8Array {
	const buffer = new ArrayBuffer(array.length * 2);
	const view = new DataView(buffer);

	for (let i = 0; i < array.length; i++) {
		const value = (array[i] as number) * 32768;
		view.setInt16(i * 2, value, true); // true for little-endian
	}

	return new Uint8Array(buffer);
}
