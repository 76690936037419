import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  useToast,
  FormErrorMessage,
  Center,
  FormHelperText,
  RadioGroup,
  Radio,
  Text,
  VStack,
  InputGroup,
  Heading,
  InputLeftAddon,
} from "@chakra-ui/react";
import { SelectButtonGroup } from "./SelectButtonGroup";
import { phoneNumberToDisplay } from "./formatters";
import { type DemoType, SCENARIOS, endDemo, startDemo } from "./demo";

export type DemoFormData = {
  email?: string;
  name?: string;
  phoneNumber?: string;
  scenarioId: string;
  type: DemoType;
};

const getUTMParameters = (): Record<string, string> => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      params[key] = value;
    }
  });
  return params;
};

async function logUtmParams(params: { utmParams: unknown; number: string }) {
  if (process.env.REACT_APP_LOG_UTM_PARAMS?.toLocaleLowerCase() === "false") {
    return;
  }
  await fetch("https://148448553686.collect.observeinc.com/v1/http", {
    method: "POST",
    headers: {
      Authorization:
        "Bearer ds1B4zLeeVzbljud7ivq:rrBxY7ESN3WbYyojVyWkr_0V0mQHliLe",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: params }), // Log the UTM parameters
  });
}

async function logFormData(formData: DemoFormData) {
  if (process.env.REACT_APP_LOG_UTM_PARAMS?.toLocaleLowerCase() === "false") {
    return;
  }
  await fetch("https://148448553686.collect.observeinc.com/v1/http", {
    method: "POST",
    headers: {
      Authorization:
        "Bearer ds1B4zLeeVzbljud7ivq:rrBxY7ESN3WbYyojVyWkr_0V0mQHliLe",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: formData }), // Log the form data
  });
}

const PhoneNumberForm = () => {
  const [demoType, setDemoType] = useState<DemoType>("phone");
  const [phoneNumber, setPhoneNumber] = useState("");

  const isConsentGiven = true;
  const [error, setError] = useState("");
  const [utmParams] = useState<Record<string, string>>(getUTMParameters());

  // Log UTM Parameters every time they change
  useEffect(() => {
    console.log("UTM Parameters:", utmParams);
  }, [utmParams]);

  const [active, setActive] = useState(false);

  const toast = useToast();

  const cta = "Start Demo Now";

  const phoneNumberPattern = /^(\([0-9]{3}\)) [0-9]{3}-[0-9]{4}$/;

  const validatePhoneNumber = (value: string) => {
    if (!value.match(phoneNumberPattern)) {
      setError("Invalid phone number format");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (active) {
      return;
    }

    if (demoType === "phone" && !validatePhoneNumber(phoneNumber)) {
      return;
    }

    if (!isConsentGiven) {
      toast({
        title: "Consent Required",
        description: "You must consent to the terms before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setActive(true);

    try {
      const formData = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as DemoFormData;
      formData.phoneNumber = phoneNumber;

      await logFormData(formData);

      // Call the function to log UTM parameters before starting demo
      await logUtmParams({
        utmParams,
        number: phoneNumber,
      });

      if (demoType === "browser") {
        toast({
          description: "Starting the Mirage Security demo now.",
          duration: 1000,
          isClosable: true,
          status: "success",
        });
      }

      // Start phone or browser demo

      const demoResult = await startDemo(formData);
      if (demoResult.error) {
        return toast({
          description: demoResult.error as string,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      return toast({
        description:
          demoResult.message || "Thank you for trying the Mirage demo.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setActive(false);
    }
  };

  const end = async () => {
    await endDemo(demoType);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const formattedPhoneNumber = phoneNumberToDisplay(event.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  return (
    <Center backgroundColor={"#FCFDFE"} overflowY={"hidden"}>
      <Box w="full" maxW="md" p={5} borderRadius="lg">
        <Heading size="lg" textAlign={"center"}>
          Try a Vishing Simulation
        </Heading>

        <form onSubmit={handleSubmit}>
          <VStack mt={4}>
            <SelectButtonGroup
              onChange={(value: string) => setDemoType(value as DemoType)}
              value={demoType}
            >
              <Button value="phone">Use My Phone</Button>
              <Button value="browser">Use My Browser</Button>
            </SelectButtonGroup>
          </VStack>

          <RadioGroup defaultValue={SCENARIOS[0].id} mt={4}>
            <VStack>
              {SCENARIOS.map((scenario) => (
                <Radio
                  name="scenarioId"
                  key={scenario.id}
                  value={scenario.id}
                  isDisabled={!scenario.isEnabled}
                >
                  <Text fontWeight="bold">{scenario.title}</Text>
                  <Text fontSize="sm">{scenario.description}</Text>
                </Radio>
              ))}
            </VStack>
          </RadioGroup>

          <>
            <FormLabel htmlFor="name" mt={4}>
              Name
            </FormLabel>
            <InputGroup>
              <Input
                id="name"
                name="name"
                placeholder="Samuel McPeterson"
                type="text"
              />
            </InputGroup>
          </>

          <FormLabel htmlFor="email-address" mt={4}>
            Email Address
          </FormLabel>
          <InputGroup>
            <Input
              name="email"
              id="email-address"
              placeholder="me@domain.com"
              type="email"
            />
          </InputGroup>

          {demoType === "phone" && (
            <>
              <FormControl
                isInvalid={error !== ""}
                isRequired={demoType === "phone"}
              >
                <FormLabel htmlFor="phone-number" mt={4}>
                  Phone Number
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon children="+1" />
                  <Input
                    id="phone-number"
                    placeholder="(123) 456-7789"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    type="tel"
                  />
                </InputGroup>
                {error ? (
                  <FormErrorMessage>{error}</FormErrorMessage>
                ) : (
                  <FormHelperText>
                    Enter your 10-digit phone number.
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}

          <Input name="type" type="hidden" value={demoType} />

          {/* <Checkbox
            mt={4}
            isChecked={isConsentGiven}
            onChange={(e) => setIsConsentGiven(e.target.checked)}
            textAlign="left"
          >
          By checking this box, 
          </Checkbox> */}
          <VStack mt={4}>
            {active && demoType === "browser" ? (
              <Button
                colorScheme="blue"
                onClick={end}
                type="button"
                width={"full"}
              >
                End Demo
              </Button>
            ) : (
              <Button
                width={"full"}
                colorScheme="blue"
                type="submit"
                isDisabled={!isConsentGiven || error !== ""}
              >
                {cta}
              </Button>
            )}

            <Text fontSize={"x-small"} textAlign={"center"}>
              By clicking "{cta}", I give Mirage consent to contact me via an
              automated and recorded call for demo purposes at my number.
            </Text>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};

export default PhoneNumberForm;
